import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  Phone,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/header.intercable.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.ikb.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.klepschgroup.jpg'

const Intercable = () => {
  const links = generateBreadcrumbsLinks('/projekte/intercable', 'Intercable')
  return (
    <Layout margin={false} color="black">
      <Helmet
        title="intercable.it - Multiportalsystem und responsive Webshop"
        meta={[
          {
            name: 'description',
            content:
              'Für das Multiportalsystem intercable.it wurde die OpenSource Lösung eZ-Publish eingesetzt. Für ein strukturiertes Management der Inhalte, ist der Auftritt bis dato in drei Portale gegliedert, ein weiteres Portal geht in den nächsten Wochen online.'
          },
          {
            name: 'keywords',
            content:
              'intercable holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Intercable, eZ Publish, ezpublish, eZ-Publish, Italien, Deutschland, Automotive'
          },
          {
            property: 'og:description',
            content:
              'Für das Multiportalsystem intercable.it wurde die OpenSource Lösung eZ-Publish eingesetzt. Für ein strukturiertes Management der Inhalte, ist der Auftritt bis dato in drei Portale gegliedert, ein weiteres Portal geht in den nächsten Wochen online.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.intercable-91e5b2570082f687802b932d334ccaea.jpg'
          }
        ]}
      />
    <BannerSmall image={BannerImage} title="Kompetenz Automobile, Elektro und Sport" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Multiportalsystem<br />und responsive Webshop
          </div>
        }
        text={
          <span>
            <p>Für das Multiportalsystem intercable.it wurde die OpenSource Lösung <a href="https://ezplatform.com/" target="_blank">eZ-Publish</a> eingesetzt. Für ein strukturiertes Management der Inhalte, ist der Auftritt bis dato in <b>drei Portale</b> gegliedert, ein weiteres Portal geht in den nächsten Wochen online.</p>
            <p>Um der internationalen Position des Unternehmens gerecht zu werden, sind alle Inhalte in <b>drei Sprachen</b> verfügbar. Wie jedes aktuelle holzweg – Projekt wurde auch der neue Webauftritt von Intercable im <b>responsiven Design</b> umgesetzt.</p>
          </span>
        }
        buttonLink="https://www.intercable.com/de/"
        buttonText="Intercable Website"
      />
      <Phone
        title="orangeFox by Intercable"
        description={
          <span>
            <p>Um shoppen von jedem Endgerät zu einem Erlebnis zu machen haben wir den Webshop für orangeFox responsive umgesetzt. Im verwendeten CMS können Bestellungen und Anfragen zu den Produkten direkt bearbeitet werden. Umgesetzt wurde der Webshop in einem Open Source Framework auf PHP Basis.<br /><br /></p>
            <p>Je nach Größe und Gewicht des Produktes, sowie der jeweiligen Länderauswahl, wird automatisch die richtige Versandoption ausgewählt. Außerdem wird für eine schnelle Abwicklung von Firmenbestellungen mittels Live Überprüfung die UID-Nummer mit der VIES Datenbank beim Checkout-Prozess abgeglichen.</p>
        </span>
        }
        mainDescription="Responsive Shop mit Open Source Framework"
        link="https://shop.orangefox.eu/"
        bgColor="#FFFFFF"
      />
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
        titleLeft="IKB Multiportal"
        imageLeft={image4}
        leftLink="/projekte/ikb"
        titleRight="Relaunch Klepsch-Gruppe"
        imageRight={image5}
        rightLink="/projekte/klepsch-group"
        titleModsRight={['white']}
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Intercable
